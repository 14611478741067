body {
 margin: 0;
 padding: 0;
 background: #070707;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, hsl(0, 0%, 1%), #0d0d0e, #0d0e0d);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right,  hsl(0, 0%, 3%), #0a0a0a, #111111); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


 
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
